<!-- 机器人 -->
<template>
  <div class="robot">
    <div class="page-tip theme-bg f24 color-666">
      <img src="../assets/images/icon-quer-nor.svg" alt="" />
      <div>{{ $i18n.t("ro_connecting_room", {num: roomList}) }}</div>
    </div>
    <div class="content">
      <div class="robot-box">
        <div class="robot-bg" @click="handleCheckRobot">
          <img src="../assets/images/robot-bg.png" alt="" />
          <div class="word color-666 f24">
            {{ isOpened ? $i18n.t("robot_click_close") : $i18n.t("ro_click_robot") }}
          </div>
        </div>
        <div class="robot-list">
          <div class="robot-item robot1">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/1-2.png"
              alt=""
            />
          </div>
          <div class="robot-item robot2">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/2-2.png"
              alt=""
            />
          </div>
          <div class="robot-item robot3">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/3-2.png"
              alt=""
            />
          </div>
          <div class="robot-item robot4">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/1-3.png"
              alt=""
            />
          </div>
          <div class="robot-item robot5">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/2-3.png"
              alt=""
            />
          </div>
          <div class="robot-item robot6">
            <img
              src="https://n-art.oss-ap-northeast-2.aliyuncs.com/images/collection/2-4.png"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="robot-data-block">
        <div class="data-item">
          <div class="word">{{ $i18n.t("ro_auctions_completed") }}</div>
          <div class="price">
            <div class="num">{{ robotInfo.count }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("my_cumulative_gain") }}</div>
          <div class="price">
            <img src="../assets/images/icon-eth-s.svg" alt="" />
            <div class="num">{{ robotInfo.ethIncome }}</div>
          </div>
        </div>
        <div class="data-item">
          <div class="word">{{ $i18n.t("gd_return") }}</div>
          <div class="price">
            <div class="num">{{ robotInfo.returnRate }}%</div>
          </div>
        </div>
      </div>
      <div class="order-block">
        <div class="title">
          <div class="word font-bold color-333 font-m f36">{{ $i18n.t("my_Order_Record") }}</div>
          <router-link class="more" to="/order">{{ $i18n.t("rd_all") }}</router-link>
        </div>
        <van-list v-model="loading" :finished="finished" @load="getAutoOrderList">
          <template slot="finished">
            {{ $i18n.t("rd_no_more") }}
          </template>
          <template slot="loading">
            {{ $i18n.t("rd_loading") }}
          </template>
          <div class="order-list">
            <div
              class="order-item"
              v-for="(item, index) in orderList"
              :key="index"
              @click="toDetail(item)"
            >
              <div class="pic-box">
                <van-image width="100%" height="3rem" fit="cover" :src="item.task.imageUrl" />
                <div
                  class="count-down"
                  v-if="
                    (orderStatus === 'CONFIRMING' || orderStatus === 'PENDING') && item.cus_time
                  "
                >
                  <van-count-down :time="item.cus_time">
                    <template #default="timeData">
                      <div class="block font-b">
                        {{ timeData.days > 9 ? timeData.days : "0" + timeData.days }}
                      </div>
                      <div class="colon">:</div>
                      <div class="block font-b">
                        {{ timeData.hours > 9 ? timeData.hours : "0" + timeData.hours }}
                      </div>
                      <div class="colon">:</div>
                      <div class="block font-b">
                        {{ timeData.minutes > 9 ? timeData.minutes : "0" + timeData.minutes }}
                      </div>
                      <div class="colon">:</div>
                      <div class="block font-b">
                        {{ timeData.seconds > 9 ? timeData.seconds : "0" + timeData.seconds }}
                      </div>
                    </template>
                  </van-count-down>
                </div>
                <div
                  :class="
                    orderStatus === 'FINISHED'
                      ? 'status-block font-b suc'
                      : 'status-block font-b fail'
                  "
                  v-if="orderStatus === 'FINISHED' || orderStatus === 'CANNELLED'"
                >
                  {{
                    orderStatus === "FINISHED"
                      ? $i18n.t("ol_Successfully_completed")
                      : orderStatus === "CANNELLED"
                      ? $i18n.t("ol_expired")
                      : ""
                  }}
                </div>
              </div>
              <div class="info-box">
                <div class="title font-m color-333 f28">{{ item.task.title }}</div>
                <div class="scan-line">
                  <div class="icon-box">
                    <img src="../assets/images/icon-eye.svg" alt="" />
                    <div class="num f24 color-999">{{ item.task.clicks }}</div>
                  </div>
                  <div class="icon-box">
                    <img src="../assets/images/icon-collet.svg" alt="" />
                    <div class="num">{{ item.task.likes }}</div>
                  </div>
                  <div class="icon-box">
                    <img src="../assets/images/stopwatch-start.svg" alt="" />
                    <div class="num">{{ item.task.createdAt }}</div>
                  </div>
                </div>
                <div class="data-block">
                  <div class="data-item">
                    <div class="word f24">{{ $i18n.t("ol_total_bid") }}</div>
                    <div class="price f24">
                      <img src="../assets/images/icon-eth-s.svg" alt="" />
                      <div class="num f24">{{ item.price_eth }}</div>
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="word f24">{{ $i18n.t("ol_My_Benefits") }}</div>
                    <div class="price f24">
                      <img src="../assets/images/icon-eth-s.svg" alt="" />
                      <div class="num f24">{{ item.income_eth }}</div>
                    </div>
                  </div>
                  <div class="data-item">
                    <div class="word f24">{{ $i18n.t("gd_return") }}</div>
                    <div class="price f24">
                      <div class="num f24">{{ item.task.income }}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
      </div>
    </div>
    <van-popup class="robot-bomb" round v-model="showBomb">
      <div class="robot-box">
        <div class="close" @click="showBomb = false">
          <img src="../assets/images/icon-close.svg" alt="" />
        </div>
        <div class="title f32 color-333">{{ $i18n.t("gd_tips") }}</div>
        <div class="title-tip f28 color-999">
          {{ $i18n.t(isOpened ? "rb_connected" : "rb_disconnected") }}
          <!-- 连接 断开 -->
        </div>
        <!-- this.$i18n.t("wi_quantity50", {Amount: this.systemConfig.minWithdrawAmount}) -->
        <van-button :loading="isBtnLoading" @click="handleConfirm" round class="btn confirm-btn"
          >{{ !isOpened ? $i18n.t("new_robot_open") : $i18n.t("new_robot_close") }}
          {{ $i18n.t("new_robot_connect") }}</van-button
        >
      </div>
    </van-popup>
    <tab-bar :currentIndex="3" />
  </div>
</template>

<script>
function getFullNum(num) {
  //处理非数字
  if (isNaN(num)) {
    return num;
  }
  //处理不需要转换的数字
  var str = "" + num;
  if (!/e/i.test(str)) {
    return num;
  }
  return num.toFixed(18).replace(/\.?0+$/, "");
}
import TabBar from "@/components/TabBar.vue";
import dayjs from "dayjs";
import {utcDateSubtract} from "../utils/day";
export default {
  components: {
    TabBar,
  },
  data() {
    return {
      refreshing: false,
      loading: true,
      finished: true,
      showBomb: false,
      time: 0,
      timeData: {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      },
      orderStatus: "PENDING",
      statusOptions: [
        {
          name: "my_in_progress",
          value: "CONFIRMING",
        },
        {
          name: "rd_in_task",
          value: "PENDING",
        },
        {
          name: "rd_Completed",
          value: "FINISHED",
        },
        {
          name: "my_Failed",
          value: "CANNELLED",
        },
      ],

      isOpened: false,
      isBtnLoading: false,
      roomList: 0,
      robotInfo: {
        count: 0,
        ethIncome: "0",
        returnRate: "0.00",
      },
      orderList: [
        {
          task: {},
          order: {},
        },
      ],
    };
  },
  created() {
    this.getAutoOrderList();
    this.getAutoOrderStat();
    this.getRoomList();
    this.getShowStatus();
  },
  methods: {
    substr61(val, num = 6) {
      val = typeof val === "number" ? `${getFullNum(val)}` : val;
      const [f, n] = val.split(".");
      return val == "0" ? 0 : `${f}${n ? `.${n.substr(0, num)}` : ""}`;
    },
    utcDateFormate(date) {
      if (typeof date !== "string") return date;
      date = `${date.replace(/\./g, "-").replace(" ", "T")}Z`;
      return dayjs(date).format("YYYY-MM-DD HH:mm:ss");
    },
    // 获取自动下单列表
    async getAutoOrderList() {
      this.orderList = [];
      this.$http.getEthPrice().then((_res) => {
        if (_res.code === 0) {
          const price = _res.data.ethPrice.price;
          this.$http
            .getAutoOrder({
              page: 1,
              pageSize: 1000,
            })
            .then((res) => {
              if (res.code === 0 && res.data.total > 0) {
                this.orderList = res.data.list.map((v) => {
                  const countTime = utcDateSubtract(v.task.showAt);

                  if (v.task && v.task.createdAt) {
                    v.task.createdAt = this.utcDateFormate(v.task.createdAt);
                  }

                  return {
                    ...v,
                    price_eth: v.task ? this.substr61(v.task.price / price) : 0,
                    income_eth: v.task
                      ? this.substr61((v.task.price * v.task.income * 0.01) / price)
                      : 0,
                    cus_time: v.task ? (countTime > 0 ? countTime : 0) : 0,
                  };
                });
                this.orderList.sort(
                  (a, b) =>
                    +dayjs(this.utcDateFormate(b.task.createdAt)) -
                    +dayjs(this.utcDateFormate(a.task.createdAt))
                );
              }
              this.finished = true;
              this.loading = false;
              this.refreshing = false;
            });
        }
      });
    },
    // 获取自动下单统计
    async getAutoOrderStat() {
      const res = await this.$http.getAutoOrderStat();
      this.robotInfo = res.data;
      this.robotInfo.returnRate = this.substr61(res.data.returnRate, 2);
      this.robotInfo.ethIncome = this.substr61(res.data.ethIncome);
    },
    // 获取房间数
    async getRoomList() {
      const res = await this.$http.getRoomList();
      this.roomList = (res.data && res.data.list.length) || 0;
    },
    async getShowStatus() {
      const res = await this.$http.getUserOrderStatuss();
      // data为0，表示关闭，data为1，表示开启
      this.isOpened = Boolean(res.data);
    },
    async handleConfirm() {
      this.isBtnLoading = true;
      // status，传off，表示关闭，传on，表示开启
      try {
        const res = await this.$http.updateUserOrderStatus({status: this.isOpened ? "off" : "on"});
        if (res.code === 0) {
          this.$toast(this.$i18n.t(res.msg));
          this.getShowStatus();
          this.showBomb = false;
        }
        setTimeout(() => {
          this.isBtnLoading = false;
        }, 500);
      } catch (error) {
        this.isBtnLoading = false;
      }
    },
    toDetail(item) {
      this.$router.push("/order-info?id=" + item.order.id);
    },
    handleCheckRobot() {
      // this.$toast(this.$i18n.t("msg_functional_progress"));
      this.showBomb = true;
    },
  },
};
</script>

<style lang="less" scoped>
.robot {
  padding-top: 56px;
  padding-bottom: 1.6rem;
  .page-tip {
    line-height: 0.48rem;
    display: flex;
    justify-content: center;
    img {
      width: 0.3rem;
      height: 0.3rem;
      margin-left: 0.1rem;
      margin-top: 0.09rem;
    }
  }
  @keyframes robotBg {
    0% {
      transform: rotate(45deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .content {
    padding: 0.3rem 0;
    .robot-box {
      position: relative;
      .robot-bg {
        position: relative;
        img {
          width: 100vw;
          height: 100vw;
          transform: rotate(0deg);
          animation: robotBg 20s linear infinite;
          animation-fill-mode: backwards;
        }
        .word {
          position: absolute;
          text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
          top: calc(50% + 1rem);
          left: 50%;
          transform: translate(-50%, -50%);
          // -webkit-text-stroke: 1px #ffffff;
          // text-stroke: 1px #ffffff;
          // -webkit-background-clip: text;
          // -webkit-text-fill-color: transparent;
          line-height: 0.32rem;
        }
      }
      @keyframes robotImg {
        0% {
          transform: scale(1);
        }
        100% {
          transform: scale(1.2);
        }
      }
      .robot-list {
        .robot-item {
          box-shadow: 0px 4px 4px 0px rgba(97, 228, 194, 0.25);
          border: 1px solid #32f5db;
          position: absolute;
          border-radius: 100%;
          overflow: hidden;
          transform: scale(1);
          animation: robotImg 12s ease-in infinite;
          img {
            width: 0.96rem;
            height: 0.96rem;
            border-radius: 100%;
          }
        }
        .robot1 {
          top: 14%;
          left: 24%;
        }
        .robot2 {
          top: 14%;
          right: 24%;
        }
        .robot3 {
          top: 43.5%;
          left: 8.5%;
        }
        .robot4 {
          top: 43.5%;
          right: 8.5%;
        }
        .robot5 {
          bottom: 14%;
          left: 24%;
        }
        .robot6 {
          bottom: 14%;
          right: 24%;
        }
      }
    }
    .robot-data-block {
      padding: 0.48rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .data-item {
        margin-bottom: 0.12rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        .price {
          justify-content: center;
        }
        .word {
          flex: 1;
        }
      }
      .data-item:nth-child(2) {
        width: 40%;
      }
    }
    .order-block {
      padding: 0 0.48rem;
      .title {
        display: flex;
        line-height: 0.46rem;
        justify-content: space-between;
        margin-bottom: 0.3rem;
        .more {
          display: block;
          padding-right: 0.3rem;
          height: 0.46rem;
          background: url("../assets/images/icon-arraw-right.svg") no-repeat right center;
        }
      }
      .order-list {
        .order-item {
          background: #fff;
          border-radius: 0.48rem;
          overflow: hidden;
          padding-bottom: 0.24rem;
          margin-bottom: 0.3rem;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.05);
          .pic-box {
            display: flex;
            border-top-left-radius: 0.32rem;
            border-top-right-radius: 0.32rem;
            overflow: hidden;
            position: relative;
            img {
              border-top-left-radius: 0.32rem;
              border-top-right-radius: 0.32rem;
            }
            .count-down {
              display: flex;
              justify-content: center;
              margin-bottom: 0.4rem;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              .van-count-down {
                display: flex;
                .block {
                  width: 0.8rem;
                  height: 0.8rem;
                  background: rgba(0, 0, 0, 0.5);
                  border-radius: 0.24rem;
                  color: #fff;
                  font-size: 0.4rem;
                  line-height: 0.8rem;
                  letter-spacing: 3px;
                  font-weight: 800;
                }
                .colon {
                  font-size: 0.4rem;
                  line-height: 0.8rem;
                  font-weight: 800;
                  margin: 0 0.3rem;
                }
              }
            }
            .status-block {
              height: 0.8rem;
              line-height: 0.86rem;
              letter-spacing: 3px;
              background: rgba(0, 0, 0, 0.5);
              font-size: 0.4rem;
              font-weight: bold;
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              border-radius: 0.24rem;
              padding: 0 0.3rem;
            }
            .suc {
              color: #63ff92;
            }
            .fail {
              color: #fd4c4c;
            }
          }
          .info-box {
            padding: 0 0.24rem;
            text-align: left;
            .title {
              line-height: 0.36rem;
              padding: 0.16rem 0;
            }
            .scan-line {
              display: flex;
              margin-bottom: 0.2rem;
              .icon-box {
                height: 0.36rem;
                line-height: 0.36rem;
                display: flex;
                margin-right: 0.3rem;
                img {
                  width: 0.32rem;
                  height: 0.32rem;
                  margin-top: 1px;
                  margin-right: 0.04rem;
                }
                .num {
                }
                .van-count-down {
                  display: flex;
                  line-height: 0.32rem;
                  color: #00cdff;
                  .colon {
                    line-height: 0.32rem;
                  }
                  .block {
                    font-size: 0.24rem;
                    font-weight: 400;
                    line-height: 0.32rem;
                  }
                }
              }
              .icon-box:last-child {
                margin-right: 0;
                flex: 1;
                justify-content: end;
              }
            }
            .data-block {
              display: flex;
              .data-item {
                .word {
                  margin-bottom: 0.08rem;
                }
              }
            }
          }
        }
      }
    }
  }
  .robot-bomb {
    width: 86%;
    padding: 0.48rem 0.48rem;
    .robot-box {
      .close {
        position: absolute;
        right: 0.2rem;
        top: 0.2rem;
      }
      .title {
        line-height: 0.54rem;
        margin-bottom: 0.1rem;
        text-align: left;
      }
      .title-tip {
        line-height: 0.48rem;
        text-align: left;
      }
      .confirm-btn {
        width: 100%;
        height: 0.8rem;
        line-height: 0.8rem;
        margin-top: 0.72rem;
        color: #fff;
        font-size: 16px;
      }
    }
  }
}
</style>
